import Vue from 'vue';
import Vuex from 'vuex';
import Request from '../common/request';
import moment from 'moment';
import { PARTY_LIMIT } from '../common/request';
import { venueDecoder } from '@/common/Decoder';
import magicEventsConfig from '@/common/magicEventsConfig';


const LOADING_IMAGE = 'https://imgix.cosmicjs.com/a47ded40-484a-11ee-89ab-17371fc03105-image.png';

Vue.use(Vuex);

// the root, initial state object
function isIncomingParty(x) {
  let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
  let now = moment().startOf('day');
  return partyDateEndOfDay >= now;
}

const state = {
  parties_loaded: false,
  parties: [
    {
      title: '',
      slug: '/',
      showInfo: false,
      metadata: {
        date: '2023-05-02',
        start_time: '20:00',
        main_image: { imgix_url: {} },
        brand: {},
        venue: {},
      },
    },
    {
      title: '',
      slug: 'id',
      showInfo: false,
      metadata: {
        date: '2023-05-02',
        start_time: '20:00',
        main_image: { imgix_url: {} },
        brand: {},
        venue: {},
      },
    },
  ],
  calendar: [{ metadata: { date: '' } }],
  cabo_calendar: [{ metadata: { date: '' } }],
  calendarDates: [],
  total: 0,
  caboParties: [],
  tulumVideos: [{ artist: {}, metadata: {} }],
  artistVideo: { metadata: {} },
  islaParties: [],
  incomingClubParties: {},
  article: {
    content: '',
    title: 'Loading ....',
    metadata: {
      caption: 'Loading ..',
      cover: {},
      soundcloud: '',
      instagram: '',
      genere: '',
    },
  },
  venues: [],
  venues_loaded: false,
  beach_clubs: [],
  artists: [],
  articles: [],
  links: [],
  artist: {
    title: '',
    content: '<h5>Loading ...</h5>',
    metadata: { parties: [] },
  },
  activities: [],
  faqs: [{ metadata: { category: {} } }],
  faqs_category: [{ metadata: { category: {} } }],
  clubActivity: '',
  featuredArtists: [],
  djSchedule: [{metadata: {main_image: '', start_time: '', date: '', videoid:''}}],
  communityPosts: [],
  dynamicContent: {
    content: 'TULUM TOP CLUBS ACTIVITY BY TULUM.PARTY',
    title: 'TULUM.PARTY',
    metadata: { link: '', image: { imgix_url: '' } },
  },
  brand: {
    title: '',
    metadata: {
      video: {},
      instagram_profile: '',
      main_image: {},
      past_parties: [
        {
          title: '',
          metadata: {
            video: {},
            main_image: { imgix_url: '' },
            logo: { imgix_url: {} },
          },
        },
      ],
    },
  },
  brands: [
    {
      title: '',
      metadata: {
        video: {},
        main_image: {
          imgix_url: {},
          logo: { imgix_url: {} },
          past_parties: [
            {
              title: '',
              metadata: {
                video: {},
                main_image: { imgix_url: {} },
                logo: { imgix_url: {} },
              },
            },
          ],
        },
      },
    },
  ],
  mini_parties: [],
  mini_parties_cabo: [],
  status: {
    loading: false,
    success: false,
    error: false,
  },
  party: {
    title: '',
    slug: 'id',
    showInfo: false,
    metadata: {
      venue: {
        metadata: {},
        video: {
          metadata: { previewimage: {} },
        },
      },
    },
  },
  caboparty: {
    title: '',
    slug: 'id',
    showInfo: false,
    metadata: {},
  },
  islaparty: {
    title: '',
    slug: 'id',
    showInfo: false,
    metadata: {},
  },
  festival: {
    title: '',
    metadata: { video: {}, parties: [] },
    parties: [{}],
  },
  venue: { tags: [], code: '' },
  editForm: false,
  pagination: {
    page: 1,
    limit: 30,
    total: 0,
  },
};

//BUILD DEFAULT PARTIES
let incomingParties = [];
let djSchedule = [];
for (let i = 0; i < PARTY_LIMIT; i++) {
  djSchedule.push({
    title: `Loading @ DJ ${i} | Tulum Mexico`,
    slug: '/?' + i,
    metadata: {
      start_time: '',
      videoid: '',
      artist: {metadata: {}},
      date: [
        moment().add(1,'day').format('YYYY-MM-DD'),
        moment().add(2,'day').format('YYYY-MM-DD'),
        moment().add(3,'day').format('YYYY-MM-DD')][i % 3],
      main_image: {
        imgix_url:
         LOADING_IMAGE
      },
    },

  })
  incomingParties.push({
    title: 'LOADING ...',
    slug: '/tulum-party-mexico-event-calendar',
    loading: true,
    showInfo: false,
    metadata: {
      date: moment().format('YYYY-MM-DD'),
      caption: 'Reading Party Bits ',
      start_time: '23:59',
      lineup:
        '<div style="background-color: #403d3d;height: 180px;width: 300px">' +
        '</div>',
      main_image: {
        imgix_url:
        LOADING_IMAGE
      },
      brand: { title: 'THE BEST TULUM BRANDS' },
      venue: { title: 'THE BEST TULUM CLUBS' },
    },
  });
}
state.incomingParties = incomingParties;
state.djSchedule = djSchedule.slice();
state.festival = { metadata: { parties: incomingParties.slice() } };
state.calendar = incomingParties.slice();

// define the possible getters that can be applied to our state
const getters = {
  parties(state) {
    return state.parties;
  },
  venues(state) {
    return state.venues;
  },
  artists(state) {
    return state.artists;
  },
  articles(state) {
    return state.articles;
  },
  activities(state) {
    return state.activities;
  },
  djSchedule(state) {
    return state.djSchedule;
  },
  tulumVideos(state) {
    return state.tulumVideos;
  },
  artistVideo(state) {
    return state.artistVideo;
  },
  faqs(state) {
    return state.faqs;
  },
  featuredArtists(state) {
    return state.featuredArtists;
  },
  incomingParties(state) {
    return state.incomingParties;
  },
  communityPosts(state) {
    return state.communityPosts;
  },
  dynamicContent(state) {
    return state.dynamicContent;
  },
  loading(state) {
    return state.status.loading;
  },
  pagination(state) {
    return state.pagination;
  },
  page(state) {
    return state.pagination.page;
  },
};

// define the possible mutations that can be applied to our state

function filterParties(state, payload) {
  state.parties = payload;
  state.incomingParties = [...state.parties]
    .sort((a, b) => {
      return moment.utc(a.metadata.date + 'T' + a.metadata.start_time) >
        moment.utc(b.metadata.date + 'T' + b.metadata.start_time)
        ? 1
        : -1;
    })
    .filter((x) => isIncomingParty(x));
  state.incomingClubParties = [];
  state.incomingParties.forEach((x) => {

    let club = venueDecoder(x.title).slug
    if(!club) {
      return;
    }
    let clubSlug = club.split("/")[2];
    if (clubSlug) {
      if (state.incomingClubParties[clubSlug]) {
        state.incomingClubParties[clubSlug].push(x);
      } else {
        state.incomingClubParties[clubSlug] = [];
        state.incomingClubParties[clubSlug].push(x);
      }
    }
  });
}

function filterCalendar(state, payload) {
  state.calendar = [...payload.slice()]
    .concat(magicEventsConfig)
    .sort((a, b) => {
      return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
        moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
        ? 1
        : -1;
    })
    .filter((x) => isIncomingParty(x));
}

function filterCaboCalendar(state, payload) {
  state.cabo_calendar = [...payload.slice().map((x) => {
    x.metadata.link  = x.metadata.magicEvent ? 'https://www.cabo.party/los-cabos-clubs/' : 'https://www.cabo.party/event/' + x.slug;
    x.metadata.magicEvent = true;
    return x;
  })]
    .sort((a, b) => {
      return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
      moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
        ? 1
        : -1;
    })
    .filter((x) => isIncomingParty(x));
}

const mutations = {
  SET_TOTAL(state, payload) {
    state.total = payload;
    state.pagination.total = Math.ceil(payload / state.pagination.limit);
  },
  SET_PARTIES_TOTAL(state, payload) {
    state.parties_total = payload;
  },
  SET_PARTIES(state, payload) {
    filterParties(state, payload);
    state.parties_loaded = true;
  },
  SET_CALENDAR(state, payload) {
    filterCalendar(state, payload);
    state.parties_loaded = false;
  },
  SET_CABO_CALENDAR(state, payload) {
    filterCaboCalendar(state, payload);
    state.parties_loaded = false;
  },
  SET_PARTIES_NO_CACHE(state, payload) {
    filterParties(state, payload);
    state.parties_loaded = false;
  },
  SET_CABO_PARTIES(state, payload) {
    state.caboParties = payload;
  },
  SET_ISLA_PARTIES(state, payload) {
    state.islaParties = payload;
  },
  SET_PARTY(state, payload) {
    state.party = payload;
    // state.partyBrand = brandDecoder(state.party.title)
  },
  SET_CABO_PARTY(state, payload) {
    state.caboparty = payload;
  },
  SET_ISLA_PARTY(state, payload) {
    state.islaparty = payload;
  },
  SET_BRAND(state, payload) {
    state.brand = payload;
  },
  SET_BRANDS(state, payload) {
    state.brands = payload;
  },
  SET_LINKS(state, payload) {
    state.links = payload;
  },
  SET_FESTIVAL(state, payload) {
    state.festival = payload;
  },
  SET_CLUB_EVENTS(state, payload) {
    state.club_events = payload;
  },
  SET_DJ_SCHEDULE(state, payload) {
    state.djSchedule = payload.slice().sort((a, b) => {
      let time_a = a.metadata.start_time || '00:00';
      let time_b = b.metadata.start_time || '00:00';
      return moment.utc(a.metadata.date + 'T' + time_a) >
        moment.utc(b.metadata.date + 'T' + time_b)
        ? 1
        : -1;
    });
  },
  SET_ARTICLE(state, payload) {
    state.article = payload;
  },
  SET_ARTICLES(state, payload) {
    state.articles = payload;
  },
  SET_FAQ(state, payload) {
    state.faqs = payload;
  },
  SET_FAQS_CATEGORY(state, payload) {
    state.faqs_category = payload;
  },
  SET_ACTIVITIES(state, payload) {
    state.activities = payload;
  },
  SET_VENUES(state, payload) {
    state.venues = payload;
    state.venues_loaded = true;
  },
  SET_BEACH_CLUBS(state, payload) {
    state.beach_clubs = payload;
  },
  UPDATE_VENUES(state, payload) {
    state.venues = state.venues.concat(payload);
  },
  SET_VENUE(state, payload) {
    state.venue = payload;
  },
  SET_ARTISTS(state, payload) {
    state.artists = payload;
  },
  SET_ARTIST(state, payload) {
    state.artist = payload;
  },
  SET_TULUM_VIDEOS(state, payload) {
    state.tulumVideos = payload;
  },
  SET_ARTIST_VIDEO(state, payload) {
    state.artistVideo = payload;
  },
  SET_DYNAMIC_CONTENT(state, payload) {
    state.dynamicContent = payload;
  },
  LOADING(state) {
    state.status = {
      loading: true,
      success: false,
      error: false,
    };
  },
  SUCCESS(state) {
    state.status = {
      loading: false,
      success: true,
      error: false,
    };
  },
  ERROR(state, payload) {
    state.status = {
      loading: false,
      success: false,
      error: payload,
    };
  },
  CLEAR_ERROR(state) {
    state.status = {
      loading: false,
      success: false,
      error: false,
    };
  },

  PAGINATE(state, payload) {
    state.pagination.page = payload;
  },
};

// define the possible actions that can be applied to our state
const actions = {
  getParties(context) {
    context.commit('LOADING');
    if (context.state.parties_loaded) {
      context.commit('SUCCESS');
      return;
    }
    Request.getParties(context.getters.pagination)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_PARTIES', objects);
        context.commit('SET_PARTIES_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
        throw e;
      });
  },
  getMiniParties(context) {
    return Request.getMiniParties(context.getters.pagination);
  },
  getClubParties(context, club) {
    context.commit('LOADING');
    Request.getClubParties(club.slug)
      .then((res) => {
        if (res.objects || res.message.match(/found/)) {
          // context.commit('SET_PARTIES_NO_CACHE', res.objects);
          Request.getMiniParties(context.getters.pagination).then((r) => {
            let mini = [];
            mini = r.objects.map((x) => {
              x.metadata.magicEvent = true;
              x.metadata.main_image = {imgix_url: x.metadata.image_url};
              return x;
            });
            context.commit('SET_PARTIES_NO_CACHE', mini.concat(res.objects || []));
          })
        }
        context.commit('SET_PARTIES_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      // .catch((e) => {
      //
      //   console.error(e);
      //   context.commit('ERROR', e);
      //   throw e;
      // });
  },
  get2024Parties(context) {
    context.commit('LOADING');
    Request.get2024Parties(context.getters.pagination)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_CALENDAR', objects);
        context.commit('SET_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('ERROR', e);
        throw e;
      });
  },
  getAllCaboParties(context) {
    return new Promise((resolve, reject) => {
      context.commit('LOADING');
      Request.getAllCaboParties(context.getters.pagination)
        .then((res) => {
          let parties = [];
          parties = res.objects;
          // Request.getCaboMiniParties(context.getters.pagination).then((res) => {
          //   let mini;
          //   mini = res.objects.map((x) => {
          //     x.metadata.magicEvent = true;
          //     return x;
          //   });
            context.commit('SET_CABO_CALENDAR',parties);
            context.commit('SET_TOTAL', res.total || 0);
            context.commit('SUCCESS');
            resolve(true);
          // });
        })
        .catch((error) => {
          context.commit('ERROR', error);
          reject(error);
        });
    });
  },

  getAllParties(context) {
    return new Promise((resolve, reject) => {
      context.commit('LOADING');
      Request.getAllParties(context.getters.pagination)
        .then((res) => {
          let parties = [];
          parties = res.objects;
          Request.getMiniParties(context.getters.pagination).then((res) => {
            let mini = [];
            mini = res.objects.map((x) => {
              x.metadata.magicEvent = true;
              x.metadata.main_image = { imgix_url: x.metadata.image_url };
              return x;
            });
            context.commit('SET_CALENDAR', mini.concat(parties));
            context.commit('SET_TOTAL', res.total || 0);
            context.commit('SUCCESS');
            resolve(true);
          });
        })
        .catch((error) => {
          context.commit('ERROR', error);
          reject(error);
        });
    });
  },
  getDjSchedule(context) {
    return new Promise((resolve, reject) => {
      context.commit('LOADING');

      Request.getDjSchedule(context.getters.pagination).then(
        (res) => {
          let objects = [];
          objects = res.objects;
          context.commit('SET_DJ_SCHEDULE', objects);
          context.commit('SET_TOTAL', res.total || 0);
          context.commit('SUCCESS');
          resolve(true);
        },
        (error) => {
          context.commit('ERROR', error);
          reject(error);
        }
      );
    });
  },
  getNyeDjSchedule(context) {
    return new Promise((resolve, reject) => {
      context.commit('LOADING');

      Request.getNyeDjSchedule(context.getters.pagination).then(
        (res) => {
          let objects = [];
          objects = res.objects;
          context.commit('SET_DJ_SCHEDULE', objects);
          context.commit('SET_TOTAL', res.total || 0);
          context.commit('SUCCESS');
          resolve(true);
        },
        (error) => {
          context.commit('ERROR', error);
          reject(error);
        }
      );
    });
  },
  getCaboParties(context) {
    context.commit('LOADING');
    Request.getCaboParties(context.getters.pagination)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_CABO_PARTIES', objects);
        // context.commit('SET_PARTIES_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
        throw e;
      });
  },
  getIslaParties(context) {
    context.commit('LOADING');
    Request.getIslaParties(context.getters.pagination)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_ISLA_PARTIES', objects);
        // context.commit('SET_PARTIES_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
        throw e;
      });
  },
  getParty(context, slug) {
    context.commit('LOADING');
    context.commit('SET_PARTY', { metadata: { image: {} } });
    Request.getParty(slug.slug)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_PARTY', objects[0]);
        context.commit('SET_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('ERROR', e);
        throw e;
      });
    // }
  },
  getCaboParty(context, slug) {
    context.commit('LOADING');
    context.commit('SET_CABO_PARTY', { metadata: { image: {} } });
    Request.getCaboParty(slug.slug)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_CABO_PARTY', objects[0]);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('ERROR', e);
        throw e;
      });
  },
  getIslaParty(context, slug) {
    context.commit('LOADING');
    context.commit('SET_ISLA_PARTY', { title: '', metadata: { image: LOADING_IMAGE } });
    Request.getIslaParty(slug.slug)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_ISLA_PARTY', objects[0]);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('ERROR', e);
        throw e;
      });
  },
  getVenues(context, pagination) {
    context.commit('LOADING');
    if (context.state.venues_loaded) {
      context.commit('SUCCESS');
      return;
    }
    Request.getVenues(pagination)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        if (pagination.start === 0) {
          context.commit('SET_VENUES', objects);
        } else {
          context.commit('UPDATE_VENUES', objects);
        }
        context.commit('SET_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
      });
  },
  getBeachClubs(context, pagination) {
    context.commit('LOADING');
    Request.getBeachClubs(pagination)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        if (pagination.start === 0) {
          context.commit('SET_BEACH_CLUBS', objects);
        } else {
          context.commit('SET_BEACH_CLUBS', objects);
        }
        context.commit('SET_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
      });
  },
  getVenue(context, slug) {
    context.commit('LOADING');
    context.commit('SET_VENUE', { metadata: { main_image: {} } });
    const venue = context.state.venues.find(
      (venue) => venue.slug === slug.slug
    );
    if (venue) {
      context.commit('SET_VENUE', venue);
      context.commit('SUCCESS');
    } else {
      Request.getVenue(slug.slug)
        .then((res) => {
          let objects = [];
          objects = res.objects;
          context.commit('SET_VENUE', objects[0]);
          context.commit('SET_TOTAL', res.total || 0);
          context.commit('SUCCESS');
        })
        .catch((e) => {
          console.error(e);
          context.commit('ERROR', e);
          throw e;
        });
    }
  },
  getBrand(context, slug) {
    context.commit('LOADING');
    context.commit('SET_BRAND', {
      content: '<div class="loading-text"><h3>Loading</h3></div>',
      metadata: { main_image: { imgix_url: LOADING_IMAGE, },
        past_parties:  incomingParties.slice(1,4), instagram_profile: ''},
    });
    Request.getBrand(slug.slug)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        let brand = objects[0];
        context.commit('SET_BRAND', brand);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
        throw e;
      });
  },
  getBrands(context) {
    context.commit('LOADING');
    context.commit('SET_BRANDS', { metadata: { image: {} } });
    Request.getBrands()
      .then((res) => {
        context.commit('SET_BRANDS', res.objects);
        context.commit('SET_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
        throw e;
      });
  },
  getLinks(context, slug) {
    context.commit('LOADING');
    // context.commit('SET_BRANDS', { metadata: { image: {} } });
    Request.getLinks(slug.slug)
      .then((res) => {
        context.commit('SET_LINKS', res.objects);
        // context.commit('SET_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
        throw e;
      });
  },
  getTulumTv(context, slug) {
    context.commit('LOADING');
    context.commit('SET_TULUM_VIDEOS', { artist: { metadata: {} } });
    Request.getTulumTV(slug.slug)
      .then((res) => {
        let tulumVideos = res.objects[0].metadata;
        context.commit('SET_TULUM_VIDEOS', tulumVideos);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
        throw e;
      });
  },
  getArtistVideo(context, slug) {
    context.commit('LOADING');
    context.commit('SET_ARTIST_VIDEO', { metadata: {} });
    Request.getArtistVideo(slug.slug)
      .then((res) => {
        let artistVideo = res.objects[0];
        context.commit('SET_ARTIST_VIDEO', artistVideo);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
        throw e;
      });
  },
  getFestival(context, slug) {
    context.commit('LOADING');
    // context.commit('SET_FESTIVAL', this.incomingParties);
    return new Promise((resolve, reject) => {
      return Request.getFestival(slug.slug)
        .then((res) => {
          let objects = [];
          objects = res.objects;
          let festival = objects[0];
          context.commit('SET_FESTIVAL', festival);
          context.commit('SUCCESS');
          resolve(true);
        })
        .catch((e) => {
          console.error(e);
          context.commit('ERROR', e);
          reject(e);
        });
    });
  },

  getArtists(context, pagination) {
    context.commit('LOADING');
    Request.getArtists(pagination)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_ARTISTS', objects);
        context.commit('SET_TOTAL', res.total || 0);

        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
      });
  },

  getArtist(context, slug) {
    context.commit('LOADING');
    context.commit('SET_ARTIST', {
      title: '',
      content: '<h5>Loading ...</h5>',
      metadata: { parties: [], flag: '' },
    });
    Request.getArtist(slug.slug)
      .then((res) => {
        let objects = [];
        objects = res.objects[0];
        context.commit('SET_ARTIST', objects);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
      });
  },
  getActivities(context) {
    context.commit('LOADING');
    Request.getActivities()
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_ACTIVITIES', objects);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
      });
  },
  getArticle(context, slug) {
    context.commit('LOADING');
    context.commit('SET_ARTICLE', {
      title: 'Spinning The Hamster',
      content: '<div class="large-loading-text"><h3>Loading</h3></div>',
      metadata: { cover: { imgix_url: LOADING_IMAGE, },
    }});
    Request.getArticle(slug.slug)
      .then((res) => {
        context.commit('SET_ARTICLE', res.objects[0]);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('ERROR', e);
      });
  },
  getArticles(context, pagination) {
    context.commit('LOADING');
    Request.getArticles(pagination)
      .then((res) => {
        context.commit('SET_ARTICLES', res.objects);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('ERROR', e);
      });
  },
  getFaq(context) {
    context.commit('LOADING');
    Request.getFaqs()
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_FAQ', objects);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('ERROR', e);
      });
  },
  getFaqsByCategory(context, category) {
    context.commit('LOADING');
    context.commit('SET_FAQS_CATEGORY', {});
    Request.getFaqsByCategory(category)
      .then((res) => {
        let objects = [];
        objects = res.objects;
        context.commit('SET_FAQS_CATEGORY', objects);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('SET_FAQS_CATEGORY', {});
        context.commit('SUCCESS');

        // context.commit('ERROR', e);
      });
  },

  getDynamicContent(context) {
    context.commit('LOADING');
    Request.getDynamicContent()
      .then((res) => {
        context.commit('SET_DYNAMIC_CONTENT', res.objects[0]);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('ERROR', e);
      });
  },

  paginate(context, payload) {
    context.commit('PAGINATE', payload);
    context.dispatch('getParties');
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});
