import MainLayout from '../pages/Layout/MainLayout.vue';
const HomePage  = () => import('../pages/Home/Home.vue');
const Club  = () => import('@/pages/Venues/Club');
const BeachClubs = () => import('@/pages/Venues/BeachClubs');
const Clubs = () => import('@/pages/Venues/Clubs');
const Community = () => import('@/pages/Community/Community');
const Artists = () => import('@/pages/Artists/Artists');
const Artist = () => import('@/pages/Artists/Artist');
const Brand = () => import('@/pages/Brands/Brand');
const Brands = () => import('@/pages/Brands/Brands');
const PartyCalendar = () => import('@/pages/PartyCalendar/PartyCalendar');
const Event = () => import('@/pages/Events/Event');
const CaboEvent = () => import('@/pages/Events/CaboEvent');
const IslaEvent = () => import('@/pages/Events/IslaEvent');
const About  = () => import('@/pages/About/About');
const Contact = () => import('@/pages/Contact/Contact');
const Faq = () => import('@/pages/Faq/Faq');
const Article = () => import('@/pages/Article/Article');
const Blog = () => import('@/pages/Article/Blog');
const TulumNye2024 = () => import('@/pages/TulumNye/TulumNye2024');
// const TulumNye2023 = () => import('@/pages/TulumNye/TulumNye2023');
const PhotoViewer= () => import('@/pages/PhotoViewer/PhotoViewer');
const WeddingPlanning = () => import('@/pages/WeddingPlanner/WeddingPlanning');
const ConciergeServices = () => import('@/pages/ConciergeServices/ConciergeServices');
const Search = () => import('@/pages/Search/Search');
const TulumVideos = () => import('@/pages/TuluMTV/TulumVideos');
const TulumVideo = () => import('@/pages/TuluMTV/TulumVideo');
const TulumShorts = () => import('@/pages/TulumShort/TulumShorts');
const IslaMujeres = () => import("@/pages/islaMujeres/IslaMujeres");
const HoryzonTulum2022 = () => import('@/pages/Festivals/HoryzonTulum2022');
const OutdoorActivities = () => import('@/pages/OutdoorActivities/OutdoorActivities');
const Cabo = () => import('@/pages/Cabo/Cabo');
const TulumNightLife = () => import('@/pages/TulumNightLife/TulumNightLife');
const TulumDjSchedule = () => import('@/pages/TulumDjSchedule/TulumDjSchedule');
const Redirector = () => import('@/pages/Redirector/Redirector');
const ZamnaOfficialSellers = () => import('@/pages/OfficialSellers/ZamnaOfficialSellers');

import redirects from "@/pages/redirects";
// GeneralViews
// import NotFound from 'template_reference/GeneralViews/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    component: MainLayout,
    name: 'Main',
    redirect: {
      name: 'Home',
    },
    children: [
      // Redirects
      ...redirects,
      {
        path: '/',
        name: 'Home',
        component: HomePage,
      },
      {
        path: '/event/:slug',
        name: 'Event',
        component: Event,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/san-jose-cabo/event/:slug',
        name: 'CaboEvent',
        component: CaboEvent,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/isla-mujeres/event/:slug',
        name: 'IslaEvent',
        component: IslaEvent,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/activities',
        name: 'OutdoorActivities',
        components: { default: OutdoorActivities },
      },
      {
        path: '/djs',
        name: 'Artists',
        components: { default: Artists },
      },
      {
        path: '/tulum-dj-schedule',
        name: 'TulumDjSchedule',
        components: { default: TulumDjSchedule },
      },
      {
        path: '/djs/:slug',
        name: 'Artist',
        component: Artist,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/about',
        name: 'About',
        components: { default: About },
      },
      {
        path: '/event/brands/:slug',
        redirect: (route) => {
          return '/brands/'+route.params.slug ;
        },
      },
      {
        path: '/brands/:slug',
        name: 'Brand',
        component: Brand,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/brands',
        name: 'Brands',
        component: Brands,
      },
      {
        path: '/tulum-party-mexico-event-calendar',
        name: 'PartyCalendar',
        // meta: { hideFooter: true},
        component: PartyCalendar,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/calendar',
        redirect: () => {
          return '/tulum-party-mexico-event-calendar';
        },
      },
      {
        path: '/venues',
        name: 'Venues',
        redirect: () => {
          return '/tulum-clubs/';
        },
      },
      {
        path: '/tulum-clubs',
        name: 'Clubs',
        component:  Clubs ,
      },
      {
        path: '/tulum-clubs/vagalume',
        name: 'old-vagalume',
        redirect: () => {
          return '/tulum-clubs/vagalume-tulum';
        },
      },
      {
        path: '/tulum-clubs/taboo',
        name: 'old-taboo',
        redirect: () => {
          return '/tulum-clubs/taboo-tulum';
        },
      },
      {
        path: '/tulum-day-beach-clubs',
        name: 'BeachClubs',
        component: BeachClubs,
      },
      {
        path: '/tulum-clubs/:slug',
        name: 'Club',
        component: Club,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/tulum-concierge-services',
        name: 'ConciergeServices',
        component: ConciergeServices,
      },
      {
        path: '/search',
        name: 'Search',
        component: Search,
      },
      {
        path: '/tulum-wedding-planners',
        name: 'WeddingPlanning',
        component: WeddingPlanning,
      },
      {
        path: '/contact',
        name: 'Contact',
        components: Contact,
      },
      {
        path: '/blog',
        name: 'Blog',
        component: Blog,
      },
      {
        path: '/article/:slug',
        name: 'Article',
        component: Article,
        props: (route) => ({ slug: route.params.slug }),

      },
      {
        path: '/faq',
        name: 'FaqShort',
        redirect: () => {
          return '/tulum-traveler-frequent-questions';
        },
      },
      {
        path: '/tulum-traveler-frequent-questions',
        name: 'Faq',
        component: Faq,
      },
      {
        path: '/community',
        name: 'Community',
        component:  Community,
      },
      {
        path: '/tulum-new-year-events-2022',
        redirect: () => {
          return '/tulum-new-year-festival-2024';
        },
      },
      {
        path: '/tulum-new-year-festival-2023',
        redirect: () => {
          return '/tulum-new-year-festival-2024';
        },
      },
      {
        path: '/tulum-new-year-festival-2024',
        name: 'TulumNye2024',
        component:TulumNye2024,
        props: (route) => ({ slug: "tulum-new-year-festival-2024" }),
      },
      {
        path: '/nye',
        name: 'TulumNye2024Short',
        component:TulumNye2024,
        redirect: () => {
          return '/tulum-new-year-festival-2024';
        },
      },
      {
        path: '/horyzon-festival-tulum-2022',
        name: 'HoryzonTulum2022',
        component:HoryzonTulum2022,
        props: (route) => ({ slug: "horyzon-festival-tulum-2022" }),
      },
      {
        path: '/san-jose-cabo-party',
        name: 'CaboIndex',
        component: Cabo,
      },
      {
        path: '/isla-mujeres-party',
        name: 'IslaMujeres',
        component: IslaMujeres,
      },
      {
        path: '/tulum-nightlife',
        name: 'TulumNightLife',
        component: TulumNightLife,
      },
      {
        path: '/tulum-tv',
        name: 'TulumVideos',
        component: TulumVideos,
      },
      {
        path: '/tulum-videos/:klass/:slug',
        name: 'TulumVideo',
        component: TulumVideo,
        props: (route) => ({ slug: route.params.slug, klass: route.params.klass }),
      },
      {
        path: '/tulum-shorts/:slug',
        name: 'TulumShort',
        component: TulumShorts,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/photos/:slug',
        name: 'PhotoViewer',
        component: PhotoViewer,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/l',
        name: 'Redirector',
        component: Redirector,
        props: (route) => ({ slug: route.query.u }),
      },
      {
        path: 'zamna-tulum-official-sellers',
        component: ZamnaOfficialSellers,
      },
      {
        path: '/google-calendar-party',
        name: 'GoogleCalendar',
        beforeEnter() {
          window.location.href = 'https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23616161&ctz=America%2FCancun&showCalendars=1&showNav=0&showTitle=0&showDate=0&showPrint=0&showTabs=1&mode=AGENDA&src=Y18xY28xN25wdjFkb2dhOG45cDZpMjFucXN1Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23D50000';
        },

      },
     ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

export default routes;
